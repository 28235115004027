<template>
    <b-card title="Edit Offer">
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
                <b-row>

                    <!-- Country -->
                    <b-col cols="12">
                        <b-form-group label="Select Country" label-for="country">
                            <validation-provider name="Country" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MapPinIcon" />
                                    </b-input-group-prepend>
                                    <b-form-select id="country" @change="onchange(item.country)" v-model="item.country"
                                        :options="countries" :state="errors.length > 0 ? false : null">
                                    </b-form-select>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Company -->
                    <b-col cols="12">
                        <b-form-group label="Select Company" label-for="company">
                            <validation-provider name="Company" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MapPinIcon" />
                                    </b-input-group-prepend>
                                    <b-form-select id="company" v-model="item.company" :options="companyOptions"
                                        :state="errors.length > 0 ? false : null">
                                    </b-form-select>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Title -->
                    <b-col cols="12">
                        <b-form-group label="Title" label-for="title">
                            <validation-provider name="title" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="title" v-model="item.title" :state="errors.length > 0 ? false : null"
                                        placeholder="Title" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Genrate Offer Code -->
                    <b-col cols="12">
                        <b-form-group label="Offer Code" label-for="Offer Code">
                            <validation-provider name="offer_code" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="offer_code" v-model="item.offer_code"
                                        :state="errors.length > 0 ? false : null" placeholder="Offer Code" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Estimated Saving -->
                    <b-col cols="12">
                        <b-form-group label="Estimated Saving" label-for="Estimated Saving">
                            <validation-provider name="amount" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="amount" v-model="item.amount"
                                        :state="errors.length > 0 ? false : null" placeholder="Estimated Saving" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Valid Untill -->
                    <b-col cols="12">
                        <b-form-group label="Valid Untill" label-for="Valid Untill">
                            <validation-provider name="date" rules="required|max:20" #default="{ errors }">
                                <b-form-datepicker id="example-datepicker" v-model="item.valid_date" class="mb-2">
                                </b-form-datepicker>
                                <p>Date: '{{ valid_date }}'</p>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Reuseable -->
                    <b-col cols="12">
                        <b-form-group label="Is Reuseable" label-for="is_reuseable">
                            <b-form-checkbox id="is_reuseable" v-model="item.is_reuseable" name="is_reuseable" value="1"
                                unchecked-value="0">
                                Is Reuseable
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>

                    <!-- Add to Home Page "Top intro Offer list" -->
                    <b-col cols="12">
                        <b-form-group label="Add to Home Page 'Top intro Offer list'" label-for="is_reuseable">
                            <b-form-checkbox id="blogshowoption" v-model="item.blogshowoption" name="blogshowoption" value="1"
                                unchecked-value="0">
                                Add to Home Page
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>

                    <!-- Amenities -->
                    <b-col cols="12">
                        <b-form-group label="Amenities" label-for="Amenities">
                            <div v-for="(find, index) in amenities" :key="find.id">
                                <b-input-group>
                                    <b-form-input v-model="amenities1[index]" min="0.00"></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="deleteAmenities(index)" variant="outline-primary">Delete
                                        </b-button>
                                        <b-input-group-text>
                                            <b-icon icon="x" />
                                        </b-input-group-text>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                            <div id="add"></div>
                            <b-col>
                                <b-button @click="addAmenities" variant="success">
                                    <feather-icon icon="PlusIcon" />
                                </b-button>
                            </b-col>
                        </b-form-group>
                    </b-col>

                    <!-- Offer Type -->
                    <b-col cols="12">
                        <b-form-group label="Offer Type" label-for="Offer Type">
                            <div v-for="(find, index) in offer_types" :key="find.id">
                                <b-input-group>
                                    <b-form-input v-model="offer_types1[index]" min="0.00"></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="deleteOfferType(index)" variant="outline-primary">Delete
                                        </b-button>
                                        <b-input-group-text>
                                            <b-icon icon="x" />
                                        </b-input-group-text>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                            <div id="add"></div>
                            <b-col>
                                <b-button @click="addOfferType" variant="success">
                                    <feather-icon icon="PlusIcon" />
                                </b-button>
                            </b-col>
                        </b-form-group>
                    </b-col>

                    <!-- Offers -->
                    <b-col cols="12">
                        <b-form-group label="Offers" label-for="Offers">
                            <div v-for="(find, index) in offers" :key="find.id">
                                <b-input-group>
                                    <b-form-input v-model="offers1[index]" min="0.00"></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="deleteOffers(index)" variant="outline-primary">Delete
                                        </b-button>
                                        <b-input-group-text>
                                            <b-icon icon="x" />
                                        </b-input-group-text>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                            <div id="add"></div>
                            <b-col>
                                <b-button @click="addOffers" variant="success">
                                    <feather-icon icon="PlusIcon" />
                                </b-button>
                            </b-col>
                        </b-form-group>
                    </b-col>

                    <!-- Tag -->
                    <b-col cols="12">
                        <b-form-group label="Tag" label-for="Tag">
                            <div v-for="(find, index) in finds" :key="find.id">
                                <b-input-group>
                                    <b-form-input v-model="finds1[index]" min="0.00"></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="deleteTag(index)" variant="outline-primary">Delete</b-button>
                                        <b-input-group-text>
                                            <b-icon icon="x" />
                                        </b-input-group-text>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                            <div id="add"></div>
                            <b-col>
                                <b-button @click="addTag" variant="success">
                                    <feather-icon icon="PlusIcon" />
                                </b-button>
                            </b-col>
                        </b-form-group>
                    </b-col>

                    <!--Short Description -->
                    <b-col cols="12">
                        <b-form-group label="Short Description" label-for="Short description">
                            <validation-provider name="short_description" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>
                                    <b-form-textarea id="short_description" v-model="item.short_description"
                                        :state="errors.length > 0 ? false : null" placeholder="Enter something..." rows="5"
                                        size="lg" max-rows="10"></b-form-textarea>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Description -->
                    <b-col cols="12">
                        <b-form-group label="Description" label-for="description">
                            <validation-provider name="description" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <ckeditor :editor="editor" :config="editorConfig" id="description"
                                        v-model="item.description" class="form-control form-control-lg"></ckeditor>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Profile Preview -->

                    <b-col cols="12">
                        <b-img thumbnail fluid :src="previewImg" :class="previewImg.length <= 0 ? 'd-none' : null"
                            alt="Image"></b-img>
                    </b-col>

                    <!-- Profile Image -->
                    <b-col cols="12">
                        <b-form-group label="Profile Image" label-for="profile">
                            <validation-provider name="Profile Image" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="ImageIcon" />
                                    </b-input-group-prepend>
                                    <b-form-file id="profile" v-model="profileImg" :state="errors.length > 0 ? false : null"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..." />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Meta Title -->
                    <b-col cols="12">
                        <b-form-group label="Meta Title" label-for="meta title">
                            <validation-provider name="meta_title" rules="required|max:50" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="meta_title" v-model="item.meta_title"
                                        :state="errors.length > 0 ? false : null" placeholder="Meta Title" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Meta Description -->
                    <b-col cols="12">
                        <b-form-group label="Meta Description" label-for="meta description">
                            <validation-provider name="meta_description" rules="required|max:200" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>

                                    <b-form-textarea id="meta_description" v-model="item.meta_description"
                                        placeholder="Enter something..." rows="5" size="lg" max-rows="10">
                                    </b-form-textarea>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Meta Keyword -->
                    <b-col cols="12">
                        <b-form-group label="Meta Keyword" label-for="meta Keyword">
                            <validation-provider name="meta_keywords" rules="required|max:50" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="meta_keywords" v-model="item.meta_keywords"
                                        :state="errors.length > 0 ? false : null" placeholder="Meta Keyword" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- offer Payment Type -->
                    <b-col cols="12">
                        <b-form-group label="Select offer Payment Type" label-for="offer Payment Type">
                            <validation-provider name="offer Payment Type" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MapPinIcon" />
                                    </b-input-group-prepend>
                                    <b-form-select id="offer_payment_type" v-model="item.offer_payment_type"
                                        :options="offerPaymentTypeOptions" :state="errors.length > 0 ? false : null">
                                    </b-form-select>
                                </b-input-group>
                                <!-- <div class="mt-3">Selected: <strong>{{ item.offer_payment_type }}</strong></div> -->
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Offer Amount -->
                    <b-col cols="12" v-if="item.offer_payment_type == 1">
                        <b-form-group label="Offer Amount" label-for="Offer Amount">
                            <validation-provider name="Offer Amount" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="offer_amount" type="number" v-model="item.offer_amount"
                                        :state="errors.length > 0 ? false : null" placeholder="Offer Amount" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Company Sharing -->
                    <b-col cols="12" v-if="item.offer_payment_type == 1">
                        <b-form-group label="Company Sharing" label-for="Company Sharing">
                            <validation-provider name="Company Sharing" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="company_sharing" type="number" v-model="item.company_sharing"
                                        :state="errors.length > 0 ? false : null" placeholder="Company Sharing" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>


                    <!-- submit and reset -->
                    <b-col>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" @click.prevent="validationForm"
                            variant="primary" class="mr-1">
                            Submit
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                            @click="reset">
                            Reset
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormText,
    BFormDatalist,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BFormDatepicker,
    BImg,
    BIcon,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
//Database
import { db, storage } from "@/main";
import firebase from "firebase";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//Vee-Validate 
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    required
} from '@validations'
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormText,
        BButton,
        BFormDatalist,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BFormDatepicker,
        BImg,
        BIcon,
        ValidationProvider,
        ValidationObserver,
        required,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            company: '',
            item: {},
            selected: '',
            options: [],
            tagg: [],
            blogshowoption: false,
            Datevalue: '',
            title: '',
            // blogshowoption: '',
            description: '',
            previewImg: '',
            price: '',
            blogshowoption: '',
            discount: '',
            vat: '',
            tag_line: '',
            tag: '',
            finds: [],
            offer_types: [],
            offer_typee: [],
            offers: [],
            amenities: [],
            extra_tags: [],
            offerr: [],
            finds1: [],
            offer_types1: [],
            offers1: [],
            amenities1: [],
            valid_date: '',
            meta_title: '',
            meta_description: '',
            meta_keywords: '',
            profileImg: '',
            offer_amount: '',
            company_sharing: '',
            paid_status: false,
            offer_payment_type: null,
            selectedCompany: null,
            offerPaymentTypeOptions: [
                { 'value': null, text: 'Please select offer Payment Type' },
                { 'value': 0, 'text': 'Free' },
                { 'value': 1, 'text': 'Paid' }
            ],
            companyOptions: [
                { 'value': null, text: 'Please select a company' },
            ],
            countryOptions: [
                { 'value': null, text: 'Please select a country' },
            ],
            countries: [],
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.

            }
        }
    },
    created() {
        this.getCountries();
        this.getItem();
        this.getCompany();
        this.getCategories();
        this.getIcons();
    },
    computed: {
        validation() {
            return this.name.length > 4 && this.name.length < 13
        },
    },
    methods: {
        addTag: function () {
            this.finds.push({ value: '' });
        },
        addOfferType: function () {
            this.offer_types.push({ value: '' });
        },
        addAmenities: function () {
            this.amenities.push({ value: '' });
        },
        addOffers: function () {
            this.offers.push({ value: '' });
        },

        deleteTag: function (index) {
            console.log(index);
            console.log(this.finds);
            this.finds.splice(index, 1);
            this.finds1.splice(index, 1);
        },
        deleteOfferType: function (index) {
            console.log(index);
            console.log(this.offer_types);
            this.offer_types.splice(index, 1);
            this.offer_types1.splice(index, 1);
        },
        deleteAmenities: function (index) {
            console.log(index);
            console.log(this.amenities);
            this.amenities.splice(index, 1);
            this.amenities1.splice(index, 1);
        },
        deleteOffers: function (index) {
            console.log(index);
            console.log(this.offers);
            this.offers.splice(index, 1);
            this.offers1.splice(index, 1);
        },
        reset() {
            this.company = '',
                this.title = '',
                this.description = '',
                this.previewImg = '',
                this.price = '',
                this.checkedNames = '',
                this.blogshowoption = '',
                this.discount = '',
                this.vat = '',
                this.tag_line = '',
                this.tag = '',
                this.offer_types = '',
                this.offer_typee = ''
            this.offers = '',
                this.extra_tags = '',
                this.offerr = ''
            this.meta_title = '',
                this.meta_description = '',
                this.meta_keywords = '',
                this.profileImg = '',
                this.offer_amount = '',
                this.company_sharing = '',
                this.offer_payment_type = null,
                this.selectedoptions = null,
                this.selectedCompany = null;
        },

        getItem() {
            var id = this.$route.params.id;

            db.collection('offers').doc(id).get().then((doc) => {
                // console.log(doc.data().tag);
                console.log(doc.data());
                this.item = doc.data();
                this.finds = doc.data().extra_tags;
                this.amenities = doc.data().tag;
                // this.extra_tags = doc.data().extra_tags;
                this.offer_types = doc.data().feature;
                this.offers = doc.data().offers;
                this.amenities1 = doc.data().tag;
                this.finds1 = doc.data().extra_tags;
                this.offer_types1 = doc.data().feature;
                this.offers1 = doc.data().offers;
                this.previewImg = doc.data().image;
                // if (this.item.blogshowoption == 1) {
                //     this.checkedNames = true;
                // }
            })
        },


        getCompany() {
            var id = this.$route.params.id;

            db.collection('offers').doc(id).get().then((doc) => {
                this.country = doc.data().country;
                db.collection("companies")
                    .where("country", "==", this.country)
                    .get()
                    .then((querySnapshot) => {
                        //console.log('hhhh1');
                        querySnapshot.forEach((doc) => {
                            this.companyOptions.push({
                                value: doc.id,
                                text: doc.data().name,
                                // slug: doc.data().slug,
                            });
                            console.log(doc.id, " => ", doc.data().name);
                        });
                    })
                    .catch((error) => {
                        console.log("Error getting documents: ", error);
                    });
            });
        },

        getCategories() {
            this.options = [];

            db.collection("companies")
                .get()
                .then((querySnapshot) => {
                    //console.log('hhhh');
                    querySnapshot.forEach((doc) => {
                        this.options.push({
                            id: doc.id,
                            name: doc.data().name,
                        });
                        //console.log(doc.id, " => ", doc.data());

                    });

                    //console.log(this.options);
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },

        getCountries() {
            this.countries = [];
            db.collection("countries")
                .orderBy('country', 'asc')
                .get()
                .then((querySnapshot) => {
                    //console.log('hhhh1');
                    querySnapshot.forEach((doc) => {
                        // console.log(doc.data())
                        db.collection("companies")
                            .where("country", "==", doc.id)
                            .get()
                            .then((querySnapshot) => {
                                querySnapshot.forEach((docs) => {
                                    var slug = doc.data().country.replace(/\s+/g, '-').toLowerCase();
                                    // console.log(doc.data())
                                    if (doc.data().status == 1) {
                                        this.countryOptions.push({
                                            value: doc.id,
                                            text: doc.data().country,
                                            slug: doc.data().slug,
                                        });
                                        const ids = this.countryOptions.map(o => o.value)
                                        this.countries = this.countryOptions.filter(({ value }, index) => !ids.includes(value, index + 1))
                                    }
                                });
                            });
                        // console.log(this.options);
                    });
                    //console.log(doc.id, " => ", doc.data());
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    // alert('form submitted!')
                    this.submit();
                }
            })
        },
        onImageChange(event) {
            validate(event.target.files[0], 'required|image|size:100', {
                name: 'Profile Image',
            }).then(result => {
                if (result.valid) {
                    const fileReader = new FileReader();
                    fileReader.onload = (event) => {
                        this.previewImg = event.target.result;
                    }
                    fileReader.readAsDataURL(event.target.files[0]);
                } else {
                    this.previewImg = '';
                }
            });
        },
        submit() {
            console.log(this.offer_types)
            store.dispatch('app/commitActivateLoader');
            if (this.item.blogshowoption == true) {
                this.blogshowoption = 1;
            } else if (this.item.blogshowoption == false) {
                this.blogshowoption = 0;
            }
            if (this.item.offer_payment_type == 1) {
                this.item.offer_payment_type = 1;
                this.item.paid_status = true;
            } else {
                this.item.offer_payment_type = 0;
                this.item.paid_status = false;
                this.item.offer_amount = 0
                this.item.company_sharing = 0
            }
            // alert(this.item.paid_status)
            // alert(this.blogshowoption)
            var country = "QKUvS13wB9Zo3eHZb81W"
            var currency = [];
            db.collection("currency")
                // .where("country_id", "==", this.item.country ?? country)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        if (doc.data().country_id == this.item.country) {
                            // console.log(doc.data())
                            currency = doc.data().code
                        }
                        if (doc.data().country_id == country) {
                            currency = doc.data().code
                        }
                        // console.log(currency)
                    })
                    console.log(currency)
                    var id = this.$route.params.id;
                    var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
                    db.collection("offers")
                        .doc(id)
                        .update({
                            country: this.item.country,
                            currency: currency,
                            company: this.item.company,
                            title: this.item.title,
                            offer_code: this.item.offer_code,
                            amount: this.item.amount,
                            valid_date: this.item.valid_date,
                            is_reuseable: this.item.is_reuseable,
                            // extra_tags: this.item.tag,
                            blogshowoption: this.blogshowoption,
                            tag: this.amenities1,
                            feature: this.offer_types1,
                            offers: this.offers1,
                            extra_tags: this.finds1,
                            short_description: this.item.short_description,
                            description: this.item.description,
                            offer_amount: this.item.offer_amount,
                            company_sharing: this.item.company_sharing,
                            paid_status: this.item.paid_status,
                            offer_payment_type: this.item.offer_payment_type,
                            meta_title: this.item.meta_title,
                            meta_description: this.item.meta_description,
                            meta_keywords: this.item.meta_keywords,
                            created: date,
                            modified: date,
                        })
                        .then((doc) => {
                            console.log("Added Successfully!");
                            const filename = this.profileImg.name;
                            if (filename) {
                                const ext = filename.slice(filename.lastIndexOf('.'));
                                const storageRef = storage.ref();
                                const fileRef = storageRef.child('offers/' + id + "." + ext);
                                fileRef.put(this.profileImg).then(result => {
                                    fileRef.getDownloadURL().then(imageUrl => {
                                        console.log(imageUrl);
                                        db.collection("offers").doc(id).update({ image: imageUrl })
                                        store.dispatch('app/commitDeactivateLoader');
                                        this.$router.push({ name: 'company-view', params: { id: this.item.company } });
                                        this.showNotification('Offer Updated Successfully!', 'AlignJustifyIcon', 'success');
                                    }).catch((error) => {
                                        console.error("Error writing document: ", error);
                                        this.showNotification('Error! Request Failed!', 'AlignJustifyIcon', 'danger');
                                    });
                                });
                            }
                            store.dispatch('app/commitDeactivateLoader');
                            this.$router.push({ name: 'company-view', params: { id: this.item.company } });
                            this.showNotification('Offer Updated Successfully!', 'AlignJustifyIcon', 'success');
                        })
                })
            // var id = localStorage.getItem("userSessionId");
            // console.log('rrr ' + id);
        },

        onchange: function (option) {
            // this.companyOptions = [];
            this.companyOptions = [
                { 'value': null, 'text': 'Please select a company' },
            ],
                // console.log(option);
                db.collection("companies")
                    .where("country", "==", option)
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            this.companyOptions.push({
                                value: doc.id,
                                text: doc.data().name,
                                // slug: doc.data().slug,
                            });
                            console.log(doc.id, " => ", doc.data());
                        });
                    })
                    .catch((error) => {
                        console.log("Error getting documents: ", error);
                    });
        },

        showNotification(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            })
        },
    },
}
</script>